import React, { Component } from 'react';

export class EmailForm extends Component {
  render() {
    return (
      <form
        method="post"
        name="newsletter-en"
        action="/en/success"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="newsletter-en" />
        <div className="form-group">
          <label>Email</label>
          <input
            className="form-control form-control-lg"
            type="email"
            name="email"
            id="email"
            required
            placeholder="Your email ..."
          />
        </div>
        <button className="btn btn-warning btn-lg mt-3 mr-3" type="submit">
          Validate
        </button>
      </form>
    );
  }
}

export default EmailForm;
