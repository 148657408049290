import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/en';
import { EmailForm } from '../components/newsletter/EmailFormEn';
import SEO from '../components/seo';

export const Newsletter = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Newsletter - Furlan Snowboards"
      description="Subscribe to our newsletter"
      pathname="/en/newsletter/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="newsletterPage">
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-7 my-auto">
              <div className="header-content mx-auto">
                <h1 className="mb-3">Get the latest news</h1>
                <p className="lead mb-3">Right in your inbox !<br />No abuse</p>
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
      </header>
      <blockquote className="blockquote-credit">
        @chilatax (Marcela Furlan Acosta)
      </blockquote>
    </div>
  </Layout>
);

export default Newsletter;
